/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import _ from 'lodash';
import { Field } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { ColourSelect } from 'components/Form/ColourSelect';
import RequestLoader from 'components/Loaders/Request';
import NXBox from 'components/NXBox';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import ContentEditorModuleContent from 'containers/katana/containers/ContentEditorLightbox/contentEditorModuleContent';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/queryTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { requiredFieldValidation } from 'utilities/methods/form';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { webpageColourThemeFormFieldsEnum } from './consts';

const { COLOUR } = webpageColourThemeFormFieldsEnum;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _WebpageColourThemeContentModule = () => {
    /***** HOOKS *****/
    const { id } = useSetupEditorRouteParams();
    const isMobile = useAppViewport(['xs', 'sm']);

    /***** QUERIES *****/
    const { data: get_colours_meta_data = {}, isLoading: isGetColoursMetaLoading } = katanaQuery.serviceID.meta.getColours.useQuery(Number(id));

    /***** FUNCTIONS *****/
    function createColorKeysMobile() {
        const clonedColourKeys = _.cloneDeep(colourKeysNotMobile);
        const colourKeysMobile1 = clonedColourKeys.slice(0, 5).concat(clonedColourKeys.slice(10, 15)).concat(clonedColourKeys.slice(20, 25));
        const colourKeysMobile2 = colourKeysMobile1
            .concat(clonedColourKeys.slice(5, 10))
            .concat(clonedColourKeys.slice(15, 20))
            .concat(clonedColourKeys.slice(25, 30));
        const colourKeysMobile3 = colourKeysMobile2.concat(clonedColourKeys.slice(30, 34));
        return colourKeysMobile3;
    }

    /***** RENDER HELPERS *****/
    const colourKeysNotMobile = _.keys(get_colours_meta_data);
    const colourKeysMobile = createColorKeysMobile();
    const colourKeys = isMobile ? colourKeysMobile : colourKeysNotMobile;
    const colourOptions = colourKeys.map((colourKey) => {
        const { id, hex_code } = get_colours_meta_data[colourKey];
        return { id, hex_code };
    });

    /***** RENDER *****/
    return (
        <NXBox.TopAccordion
            title="Website Colour Scheme"
            description="Feel free to customise the default colour scheme applied from your chosen template. Select a colour that closely aligns with your brand's identity."
        >
            <NXBox.DefaultPadding>
                <ContentEditorModuleContent>
                    {isGetColoursMetaLoading ? <RequestLoader message="Loading Theme Colours..." /> : ''}
                    <Field
                        name={COLOUR}
                        component={ColourSelect.ReduxForm}
                        preventDeselect
                        options={colourOptions}
                        validate={[requiredFieldValidation]}
                    />
                    <br />
                    <Text italic size--xss secondary>{`Don't worry, you'll be able to change your colour later if you change your mind`}</Text>
                </ContentEditorModuleContent>
            </NXBox.DefaultPadding>
        </NXBox.TopAccordion>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const WebpageColourThemeContentModule = Object.assign(_WebpageColourThemeContentModule, {
    formFields: webpageColourThemeFormFieldsEnum
});

export default WebpageColourThemeContentModule;
