import { checkDomain } from 'containers/katana/queries/checkDomain';
import { getDomainsList } from 'containers/katana/queries/getDomainsList';
import { getSiteList } from 'containers/katana/queries/getSiteList';
import { searchUnsplash } from 'containers/katana/queries/searchUnsplash';
import { getBusinessInfo } from 'containers/katana/queries/serviceID/getBusinessInfo';
import { getDNS } from 'containers/katana/queries/serviceID/getDNS';
import { getDomainOptions } from 'containers/katana/queries/serviceID/getDomainOptions';
import { getNavigation } from 'containers/katana/queries/serviceID/getNavigation';
import { getNavigationItems } from 'containers/katana/queries/serviceID/getNavigationItems';
import { getPresetPreview } from 'containers/katana/queries/serviceID/getPresetPreview';
import { getSingleSection } from 'containers/katana/queries/serviceID/getSection';
import { getSectionPreview } from 'containers/katana/queries/serviceID/getSectionPreview';
import { getSectionSitePreview } from 'containers/katana/queries/serviceID/getSectionSitePreview';
import { getSections } from 'containers/katana/queries/serviceID/getSections';
import { getService } from 'containers/katana/queries/serviceID/getService';
import { getServiceDetails } from 'containers/katana/queries/serviceID/getServiceDetails';
import { getSiteInfo } from 'containers/katana/queries/serviceID/getSiteInfo';
import { getSitePreview } from 'containers/katana/queries/serviceID/getSitePreview';
import { getSocialAccount } from 'containers/katana/queries/serviceID/getSocialAccount';
import { getSocials } from 'containers/katana/queries/serviceID/getSocials';
import { listSocialAccounts } from 'containers/katana/queries/serviceID/listSocialAccounts';
import { getColours } from 'containers/katana/queries/serviceID/meta/getColours';
import { getFonts } from 'containers/katana/queries/serviceID/meta/getFonts';
import { getSectionDefinitions } from 'containers/katana/queries/serviceID/meta/getSectionDefinitions';
import { getSocialsDefinitions } from 'containers/katana/queries/serviceID/meta/getSocialsDefinitions';
import { getThemePresets } from 'containers/katana/queries/serviceID/meta/getThemePresets';
import { palette } from 'containers/katana/queries/serviceID/meta/palette';
import { getTrialDomains } from './getTrailDomains';
import { getSingleFileInfo } from './serviceID/getSingleFile';

export const katanaQuery = Object.freeze({
    checkDomain,
    getDomainsList,
    getTrialDomains,
    getSiteList,
    searchUnsplash,
    serviceID: {
        getBusinessInfo,
        getDomainOptions,
        getNavigation,
        getNavigationItems,
        getPresetPreview,
        getSectionPreview,
        getDNS,
        getSectionSitePreview,
        getSections,
        getSingleSection,
        getService,
        getServiceDetails,
        getSiteInfo,
        getSitePreview,
        getSocials,
        listSocialAccounts,
        getSocialAccount,
        getSingleFileInfo,
        meta: {
            getColours,
            palette,
            getFonts,
            getSectionDefinitions,
            getSocialsDefinitions,
            getThemePresets
        }
    }
});
