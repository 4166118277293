/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useGetCaveat } from 'containers/katana/formFields/methods/useGetCaveat';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { CaveatTypes } from 'containers/katana/formFields/caveatTypes';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

export const _PropertyRenderText = (props) => {
    const { property } = props;
    const { caveats } = property;

    /***** RENDER HELPERS *****/
    const customTextElement = useGetCaveat(CaveatTypes.CUSTOM_TEXT, caveats);

    /***** RENDER *****/
    return <Text {...customTextElement} />;
};
