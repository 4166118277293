import { cloneElement, type ReactElement } from 'react';
import './_DialogNotificationButtonsContainer.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type DialogNotificationButtonsContainerProps = {
    text: string;
    buttons: ReactElement[];
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const DialogNotificationButtonsContainer = ({ text, buttons }: DialogNotificationButtonsContainerProps) => {
    /***** RENDER *****/
    if (!Array.isArray(buttons)) {
        return null;
    }

    return (
        <div className="DialogNotificationButtonsContainer">
            <p className="DialogNotificationButtonsContainer__text">{text}</p>
            <div className="DialogNotificationButtonsContainer__buttons">
                {buttons.map((button) => cloneElement(button, { className: 'DialogNotificationButtonsContainer__button' }))}
            </div>
        </div>
    );
};

export default DialogNotificationButtonsContainer;
