/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import BaseButton from 'components/Buttons/_BaseButton';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import { Flex } from 'components/Utils/Flex';
import RequestLoader from 'components/Loaders/Request';
import { NXSquare } from 'components/Utils/NXSquare';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import '../../_ImageSelectControl.scss';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { FileSelectControlNamespace } from './types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const FileSelectControl = ({
    url,
    fileName,
    mimeType,
    isGetSingleFileLoading,
    onClickUploadNew,
    onClickRemove,
    className
}: FileSelectControlNamespace.Props) => {
    /***** HOOKS *****/
    const isMobile = useAppViewport(['xs', 'sm']);

    /***** RENDER HELPERS *****/
    const renderPreview = () => {
        if (!mimeType) return;

        switch (mimeType) {
            case 'application/pdf':
                return (
                    <NXSquare ratio={3 / 2} className="ImageSelectControl__iframe">
                        <iframe src={url} title={fileName} height="100%" width="100%" />
                    </NXSquare>
                );
            default:
                return <div>{fileName}</div>;
        }
    };

    /***** RENDER *****/
    return (
        <div className={classNames('ImageSelectControl', className)}>
            <Flex justify="center" items="center" direction="column" className="ImageSelectControl__image">
                {isGetSingleFileLoading ? <RequestLoader /> : renderPreview()}
            </Flex>
            <Flex gap={isMobile ? 2 : 4} direction={isMobile ? 'column' : 'row'}>
                <BaseButton variant="OutlineButton" type="onClick" color="secondary" onClick={onClickUploadNew}>
                    <PhosphorIcons.UploadSimple />
                    Upload a different file
                </BaseButton>
                <BaseButton variant="OutlineButton" type="onClick" color="warn" onClick={onClickRemove}>
                    <PhosphorIcons.Trash />
                    Delete File
                </BaseButton>
            </Flex>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default FileSelectControl;
