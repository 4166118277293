/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _NXDropZone } from 'components/Form/NXDropZone/_NXDropZone';
import { _NXDropZoneHookFormDefault } from 'components/Form/NXDropZone/wrappers/hookForm/Default';
import { _NXDropZoneHookFormWithImageControl } from 'components/Form/NXDropZone/wrappers/hookForm/WithImageControl';
import { _NXDropZoneHookFormWithKatanaFileUpload } from 'components/Form/NXDropZone/wrappers/hookForm/WithKatanaFileUpload';
import { _NXDropZoneHookFormWithKatanaImageUploadAndControl } from 'components/Form/NXDropZone/wrappers/hookForm/WithKatanaImageUploadAndControl';
import { _NXDropZoneHookFormWithNamedAttachments } from 'components/Form/NXDropZone/wrappers/hookForm/WithNamedAttachments';
import { _NXDropZoneReduxFormDefault as NXDropZoneReduxFormDefault } from 'components/Form/NXDropZone/wrappers/reduxForm/Default';
import { _NXDropZoneReduxFormWithKatanaFileUpload } from 'components/Form/NXDropZone/wrappers/reduxForm/WithKatanaFileUpload';
import { _NXDropZoneReduxFormWithNamedAttachments } from 'components/Form/NXDropZone/wrappers/reduxForm/WithNamedAttachments';
import { _NXDropZoneWithKatanaFileUpload } from 'components/Form/NXDropZone/wrappers/withKatanaFileUpload';
import { _NXDropZoneReduxFormWithKatanaPDFUpload } from 'components/Form/NXDropZone/wrappers/reduxForm/WithKatanaPDFUpload';

const NXDropZone = Object.assign(_NXDropZone, {
    ReduxForm: Object.assign(NXDropZoneReduxFormDefault, {
        WithKatanaFileUpload: _NXDropZoneReduxFormWithKatanaFileUpload,
        WithNamedAttachments: _NXDropZoneReduxFormWithNamedAttachments,
        WithKatanaPDFUpload: _NXDropZoneReduxFormWithKatanaPDFUpload
    }),
    HookForm: Object.assign(_NXDropZoneHookFormDefault, {
        WithKatanaFileUpload: _NXDropZoneHookFormWithKatanaFileUpload,
        WithImageControl: _NXDropZoneHookFormWithImageControl,
        WithKatanaImageUploadAndControl: _NXDropZoneHookFormWithKatanaImageUploadAndControl,
        WithNamedAttachments: _NXDropZoneHookFormWithNamedAttachments
    }),
    Tanstack: {
        WithKatanaFileUpload: _NXDropZoneWithKatanaFileUpload
    }
});

export { NXDropZone };
