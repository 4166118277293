/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { ColourSelectCircle } from 'components/ColourSelectCircle';
import Grid from 'components/Grid';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import { brightnessByColor } from 'utilities/methods/brightnessByColor';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_ColourSelect.scss';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { ColourSelectNamespace } from 'components/Form/ColourSelect/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _ColourSelect: React.FC<ColourSelectNamespace.Props> = ({ value, onChange, options, preventDeselect = false }) => {
    /***** HOOKS *****/
    const isMobile = useAppViewport(['xs', 'sm']);

    /***** RENDER *****/
    return (
        <Grid columns={isMobile ? 'repeat(5, 1fr)' : 'repeat(10, 1fr)'} className="ColourSelect" gap={isMobile ? 2 : 1}>
            {options.map(({ id, hex_code, borderColor }) => {
                const isSelected = value === id;
                const colourBrightness = brightnessByColor(hex_code);
                const borderColourBrightness = colourBrightness && colourBrightness > 235 ? '#cfd8dc' : undefined;
                function getSelectionValue() {
                    if (isSelected) {
                        return preventDeselect ? id : null;
                    }
                    return id;
                }

                return (
                    <ColourSelectCircle
                        key={id}
                        colour={hex_code}
                        borderColor={borderColor || borderColourBrightness}
                        onSelect={() => onChange(getSelectionValue())}
                        selected={isSelected}
                    />
                );
            })}
        </Grid>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
