/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useState } from 'react';
import { useSelector } from 'react-redux';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { ServiceOverviewBanner } from 'components/ServiceOverviewBanner';
import VipRewardsHomePurchaseHostingLightbox from 'containers/vipRewards/sections/home/purchaseHostingLightbox';
import { AddHostingDropdown } from './dropdown';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OutlineButton from 'components/Buttons/OutlineButton';
import NXBox from 'components/NXBox';
import Text from 'components/Utils/Text';
import { Modal } from 'components/Lightboxes/Modal';
import { ScrollableComponent } from 'components/ScrollableComponent';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import newHostingBackground from 'assets/images/hosting/hosting_banner_background.png';
import newHostingImg from 'assets/images/hosting/hosting_banner_icon.svg';

import './_addHosting.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export function AddHosting() {
    /***** HOOKS *****/
    const [isUpgradeDropdownOpen, setIsUpdateDropdownOpen] = useState(false);
    const [upgradeType, setUpgradeType] = useState<string | null>(null);

    const [isPurchaseHostingLightboxOpen, setIsPurchaseHostingLightboxOpen] = useState(false);

    const domain_information_data = useSelector((state: any) => state.domain.domain_information_data);

    /***** RENDER *****/
    return (
        <ScrollableComponent className="addHosting">
            <NXBox initialStatus="success" isLoading={false} className="addHosting__Outer">
                <ServiceOverviewBanner slim src={newHostingBackground}>
                    <ServiceOverviewBanner.ContentWrapper>
                        <ServiceOverviewBanner.Heading size--custom={26}>Need a hosting service for this domain name?</ServiceOverviewBanner.Heading>
                        <ServiceOverviewBanner.Description>Consider adding a Select Hosting plan</ServiceOverviewBanner.Description>
                    </ServiceOverviewBanner.ContentWrapper>
                    <ServiceOverviewBanner.Image src={newHostingImg} alt="vipsites logo" />
                </ServiceOverviewBanner>

                <NXBox.Bottom>
                    <NXBox.BottomColumns
                        columns={[
                            <Text key={1} secondary size--s>
                                Our new Select hosting plans are the perfect solution for tailoring resources that better suit your hosting needs with
                                selectable plans for any size website.
                            </Text>,
                            <OutlineButton key={2} type="onClick" onClick={() => setIsUpdateDropdownOpen(true)}>
                                View Plans
                            </OutlineButton>
                        ]}
                    />
                </NXBox.Bottom>
                <NXBox.Dropdown embedded isOpen={isUpgradeDropdownOpen}>
                    <NXBox.DefaultPadding>
                        <NXBox.Dropdown.CloseButton onClose={() => setIsUpdateDropdownOpen(false)} />
                        <Text size--l bold secondary>
                            Select Hosting Type
                        </Text>
                        <AddHostingDropdown
                            action={(type: string) => {
                                setUpgradeType(type);
                                setIsPurchaseHostingLightboxOpen(true);
                            }}
                        />
                    </NXBox.DefaultPadding>
                </NXBox.Dropdown>
            </NXBox>
            <Modal isOpen={isPurchaseHostingLightboxOpen} onClose={() => setIsPurchaseHostingLightboxOpen(false)} ariaLabel="Purchase Web Hosting">
                <Modal.Title>Purchase Web Hosting</Modal.Title>
                <VipRewardsHomePurchaseHostingLightbox
                    initiallySelectedHostingType={upgradeType}
                    initialPrimaryDomain={domain_information_data?.attributes?.domain}
                />
            </Modal>
        </ScrollableComponent>
    );
}
