/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useState } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SelectDropdown from 'components/Dropdowns/SelectDropdown';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useDisableKatanaSiteMutation, useEnableKatanaSiteMutation } from 'containers/katana/queries/mutations';
import { katanaQuery } from 'containers/katana/queries/queryTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_webPageStatusGrid.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

const WebpageStatusSelectDropdown = () => {
    /***** STATE *****/
    const [statusTracker, setStatusTracker] = useState(0);

    /***** HOOKS *****/
    const { id } = useSetupEditorRouteParams();

    /***** QUERIES *****/
    const { data: serviceData } = katanaQuery.serviceID.getService.useQuery(id);
    const { mutate: mutateEnableKatanaSite, isPending: isEnableKatanaSitePending } = useEnableKatanaSiteMutation(id);
    const { mutate: mutateDisableKatanaSite, isPending: isDisableKatanaSitePending } = useDisableKatanaSiteMutation(id);

    /***** RENDER HELPERS *****/
    const siteStatus = serviceData?.attributes?.site_status;
    const isChangingStatus = isEnableKatanaSitePending || isDisableKatanaSitePending;

    /***** FUNCTIONS *****/

    function enableOptimisticStatus() {
        katanaQuery.serviceID.getService.optimisticUpdate(id, 'data.attributes.site_status', 'published');
    }

    function disableOptimisticStatus() {
        katanaQuery.serviceID.getService.optimisticUpdate(id, 'data.attributes.site_status', 'unpublished');
    }

    function enableSite() {
        setStatusTracker(statusTracker + 1);
        if (isChangingStatus) {
            return;
        }
        mutateEnableKatanaSite(null, {
            onSuccess: enableOptimisticStatus,
            onError: disableOptimisticStatus
        });
    }

    function disableSite() {
        setStatusTracker(statusTracker + 1);
        if (isChangingStatus) {
            return;
        }
        mutateDisableKatanaSite(null, {
            onSuccess: disableOptimisticStatus,
            onError: enableOptimisticStatus
        });
    }

    function getSelectOptions() {
        const disableOption = [
            {
                label: 'Unpublish',
                onClick: disableSite
            }
        ];

        const enableOption = [
            {
                label: 'Publish',
                onClick: enableSite
            }
        ];

        return siteStatus === 'published' ? disableOption : enableOption;
    }

    /***** RENDER *****/
    return <SelectDropdown key={siteStatus + statusTracker} noSelectionLabel="Edit" options={getSelectOptions()} />;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { WebpageStatusSelectDropdown };
