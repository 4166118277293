/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import htmr from 'htmr';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SolidButton from 'components/Buttons/SolidButton';

/*   ACTIONS
 *****************************************************/
import { generateId, scrollToRef } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { hostingTypes } from 'containers/vipRewards/sections/home/purchaseHostingLightbox/consts';

import './_addHostingDropdown.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export function AddHostingDropdown({ action }: { action: (type: string) => void }) {
    /***** HOOKS *****/
    const componentRef = useRef();
    const hosting_upgrade_list_data = useSelector((state: any) => state.hosting.hosting_upgrade_list_data);
    const hosting_resource_config_data = useSelector((state: any) => state.hosting.hosting_resource_config_data);

    /***** EFFECTS *****/
    useEffect(() => {
        scrollToRef(componentRef?.current);
    }, []);

    /***** RENDER HELPERS *****/
    const fallbackMinPrice = '14.00';

    const getBusinessHostingPrice = () => {
        let success = true;

        const sortedCheapestFirst = [...(hosting_upgrade_list_data || [])].sort((a, b) => {
            if (!a.attributes?.price?.Monthly || !b.attributes?.price?.Monthly) {
                success = false;
                return 0;
            }
            return Number(a.attributes.price.Monthly) > Number(b.attributes.price.Monthly) ? 1 : -1;
        });
        const lowest = sortedCheapestFirst[0]?.attributes?.price?.Monthly;

        return success && lowest ? lowest : fallbackMinPrice;
    };

    const getCustomHostingPrice = () => {
        let success = true;

        const getCheapest = (arr) => {
            const sortedCheapestFirst = arr
                .filter((option) => option.show_order)
                .sort((a, b) => {
                    if (!a?.pricing?.Monthly || !b?.pricing?.Monthly) {
                        success = false;
                        return 0;
                    }
                    return a.pricing.Monthly > b.pricing.Monthly ? 1 : -1;
                });

            return sortedCheapestFirst[0]?.pricing?.Monthly || 0;
        };

        const lowestCPU = getCheapest(hosting_resource_config_data?.attributes?.CPU || []);
        const lowestDisk = getCheapest(hosting_resource_config_data?.attributes?.['Disk Space'] || []);
        const lowestMem = getCheapest(hosting_resource_config_data?.attributes?.Memory || []);

        if (!success || !lowestCPU || !lowestDisk || !lowestMem) return fallbackMinPrice;

        return (lowestCPU + lowestDisk + lowestMem).toFixed(2);
    };

    const productTypes = [
        {
            icon: 'icon-business',
            title: 'Business Hosting',
            desc: 'Bringing your business online is now more important than ever, with our cloud-based web hosting plans your website will have the ultimate speed and security.',
            subDesc: `<p><strong>Perfect for</strong> business websites and e-commerce.</p>`,
            price: `$${getBusinessHostingPrice()}/mo`,
            template: hostingTypes.BUSINESS
        },
        {
            icon: 'icon-custom',
            title: 'Custom Hosting',
            desc: 'The power is yours with custom cPanel hosting. Custom hosting gives you the ability to select a resource limit that best suits all of your hosting needs.',
            subDesc: `<p><strong>Ideal for</strong> everyone, fully customisable hosting.</p>`,
            price: `$${getCustomHostingPrice()}/mo`,
            template: hostingTypes.CUSTOM
        }
    ];

    const handleProductSelectRender = () => {
        return productTypes.map((product) => {
            return (
                <div key={generateId()} className="addHostingDropdown__product">
                    <i
                        className={`icon icon-${product.icon} addHostingDropdown__productIcon ${
                            product.icon === 'icon-custom' ? 'purpleGradient' : 'blueGradient'
                        }`}
                    ></i>
                    <div className="addHostingDropdown__productTitle">{product.title}</div>
                    <div className="addHostingDropdown__productPrice">from {product.price}</div>
                    <div className="addHostingDropdown__productDesc">{product.desc}</div>
                    <div className="addHostingDropdown__productDivider"></div>
                    <div className="addHostingDropdown__productSubDesc">{htmr(product.subDesc)}</div>
                    <SolidButton
                        type="onClick"
                        onClick={() => {
                            action(product.template);
                        }}
                    >
                        View Plans
                    </SolidButton>
                </div>
            );
        });
    };

    /***** RENDER *****/
    return (
        <div ref={componentRef} className="addHostingDropdown">
            {handleProductSelectRender()}
        </div>
    );
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
